import { GoogleLogin } from "@react-oauth/google";
import { Card, Flex, Typography, message } from "antd";
import { start_record } from "../../../api/routes/apply";
import { useCurrentInterval } from "../../../api/queries/CurrentInterval";
import StudentData from "../../../api/types/studentData";

export default function Component({
    onVerify,
}: {
    onVerify?: (data: StudentData) => void;
}) {

    const [messageApi, contextHolder] = message.useMessage();
    const interval = useCurrentInterval();

    return (
        <Flex vertical gap={20}>
            {contextHolder}
            {
                interval.data?.description.split('\n').map((line, index) => (
                    line && <Typography.Text key={index}>{line}</Typography.Text>
                ))
            }
            <Card style={{ backgroundColor: 'var(--color-gray-2)'}}>
                <Flex vertical gap={20}>
                    <Typography.Title level={4} style={{ margin: 0 }}>
                        للمتابعة يرجى تسجيل الدخول بإستخدام البريد الالكتروني الجامعي
                    </Typography.Title>
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            messageApi.open({
                                type: 'loading',
                                content: 'جاري تسجيل الدخول',
                                duration: 20,
                            });
                            if (!credentialResponse.credential) {
                                messageApi.destroy();
                                messageApi.open({
                                    type: 'error',
                                    content: 'حدث خطأ أثناء تسجيل الدخول بواسطة جوجل'
                                });
                                return;
                            }
                            start_record({
                                token: credentialResponse.credential,
                            }).then((data) => {
                                messageApi.destroy();
                                messageApi.open({
                                    type: 'success',
                                    content: 'تم تسجيل الدخول بنجاح'
                                });
                                setTimeout(() => {
                                    onVerify && onVerify(data.data as any);
                                }, 500);
                            }).catch((error) => {
                                messageApi.destroy();
                                messageApi.open({
                                    type: 'error',
                                    content: error.message
                                });
                            });
                        }}
                        onError={() => {
                            messageApi.open({
                                type: 'error',
                                content: 'حدث خطأ أثناء تسجيل الدخول بواسطة جوجل'
                            });
                        }}
                    />
                </Flex>
            </Card>
        </Flex>
    )

}