import { Flex, Layout, Spin, Typography } from "antd";

export default function Loading({ msg }: { msg: string }) {

    return (
        <Layout
            style={{
                height: '100vh',
                width: '100vw',
            }}
        >
            <Flex
                justify="center"
                align="center"
                style={{
                    height: '100%',
                }}
                gap={20}
                vertical
            >
                <Spin size="large" />
                <Typography.Text>{msg}</Typography.Text>
            </Flex>
        </Layout>
    )

}