import { DatePicker, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useMutationForm } from "../../hooks";
import { createInterval, deleteInterval, updateInterval } from "../../api/routes/intervals";

export default function useIntervalsForm() {

    const { t } = useTranslation();

    const form = useMutationForm((
        <>
            <Form.Item
                label={t('الاسم')}
                name="name"
                initialValue={ "" }
                rules={[{ required: true, message: t('الرجاء إدخال الاسم') }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t('الوصف')}
                name="description"
                initialValue={ "" }
                rules={[{ required: true, message: t('الرجاء إدخال الوصف') }]}
            >
                <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
                label={t('تاريخ البداية')}
                name="start"
                rules={[{ required: true, message: t('الرجاء إدخال البداية') }]}
            >
                <DatePicker />
            </Form.Item>
            <Form.Item
                label={t('تاريخ النهاية')}
                name="end"
                rules={[{ required: true, message: t('الرجاء إدخال النهاية') }]}
            >
                <DatePicker />
            </Form.Item>
        </>
    ), {
        title: t('الفترة'),
        queryKey: ['intervals'],
        updateFn: updateInterval,
        createFn: createInterval,
        deleteFn: deleteInterval,
    });

    return {
        setOpen: form.setOpen,
        contextHolder: form.contextHolder,
    };

}