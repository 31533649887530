import { useQuery } from '@tanstack/react-query'

import {
    getAccount,
} from '../routes/account';

import {
    AccountType,
    QueryResponseType
} from '../types';

export const useQueryAccount = () => {
    
    const query = useQuery(
        {
            queryKey: ['account'],
            queryFn: getAccount,
            staleTime: 1000 * 60 * 60,
        }
    ) as QueryResponseType<AccountType>;

    return query;
};