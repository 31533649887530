import dayjs from 'dayjs';
import { apiWithInterceptor } from '.';
import { IntervalType } from '../types';

export const getIntervals = async (params: {
    limit?: number;
    offset?: number;
    search?: string;
}): Promise<IntervalType> => {
    return await apiWithInterceptor.get('/forms', { params });
}

export const getInterval = async (id: number): Promise<IntervalType> => {
    return await apiWithInterceptor.get(`/forms/${id}`);
}

export const createInterval = async (data: {
    name: string;
    start: string;
    end: string;
}): Promise<IntervalType> => {
    return await apiWithInterceptor.post('/forms', data);
}

export const updateInterval = async (id: number, data: {
    name?: string;
    start?: string;
    end?: string;
}): Promise<IntervalType> => {
    if (data.start) data.start = dayjs(data.start).format('YYYY-MM-DD HH:mm:ss');
    if (data.end) data.end = dayjs(data.end).format('YYYY-MM-DD HH:mm:ss');
    return await apiWithInterceptor.put(`/forms/${id}`, data);
}

export const deleteInterval = async (id: number): Promise<IntervalType> => {
    return await apiWithInterceptor.delete(`/forms/${id}`);
}