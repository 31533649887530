import { Form, Input, Button, Flex, Typography, Alert, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Logo } from '../components';
import { useEffect, useState } from 'react';
import { login, loginGoogle } from '../api/routes/auth';
import { useNavigate } from 'react-router-dom';
import { getAccount } from '../api/routes/account';
import { GoogleLogin } from '@react-oauth/google';

type LayoutType = Parameters<typeof Form>[0]['layout'];

export default function Login() {

    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const onFinish = (values: any) => {
        setLoading(true);
        login(values).then((data) => {
            localStorage.setItem('token', data.token);
            navigate('/app');
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');

    const [form] = Form.useForm<{ email: string, password: string }>();
    Form.useWatch(() => setError(null), form);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            getAccount().then(() => {
                navigate('/app');
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    const [messageApi, contextHolder] = message.useMessage();

    return (
        <Flex vertical align="start" gap={20} style={{ width: 400, padding: '0 20px' }}>
            {contextHolder}
            <Flex vertical gap={10}>
                <Logo width={100} />
                <Typography.Title level={4} style={{ margin: 0 }}>{t('تسجيل الدخول')}</Typography.Title>
                <Typography.Text>{t('قم بإدخال المعلومات المطلوبة لللمتابعة في استخدام النظام')}</Typography.Text>
            </Flex>
            <div style={{ height: '5%' }} />

            {
                error && (
                    <Alert message={error} type="error" showIcon />
                )
            }


            <GoogleLogin
                onSuccess={credentialResponse => {
                    messageApi.open({
                        type: 'loading',
                        content: 'جاري تسجيل الدخول',
                        duration: 20,
                    });
                    if (!credentialResponse.credential) {
                        messageApi.destroy();
                        messageApi.open({
                            type: 'error',
                            content: 'حدث خطأ أثناء تسجيل الدخول بواسطة جوجل'
                        });
                        return;
                    }
                    loginGoogle({
                        token: credentialResponse.credential,
                    }).then((data) => {
                        messageApi.destroy();
                        messageApi.open({
                            type: 'success',
                            content: 'تم تسجيل الدخول بنجاح'
                        });
                        setTimeout(() => {
                            localStorage.setItem('token', data.token);
                            navigate('/app');
                        }, 1000);
                    }).catch((error) => {
                        messageApi.destroy();
                        messageApi.open({
                            type: 'error',
                            content: error.message
                        });
                    });
                }}
                onError={() => {
                    messageApi.open({
                        type: 'error',
                        content: 'حدث خطأ أثناء تسجيل الدخول بواسطة جوجل'
                    });
                }}
            />;

            <Form
                onFinish={onFinish}
                form={form}
                layout={formLayout}
                style={{ width: '100%' }}
            >
                <Form.Item
                    label={t('البريد الإلكتروني')}
                    name="email"
                    rules={[{ required: true, message: t('الرجاء إدخال البريد الإلكتروني') }]}
                >
                    <Input type="email" disabled={loading} />
                </Form.Item>

                <Form.Item
                    label={t('كلمة المرور')}
                    name="password"
                    rules={[{ required: true, message: t('الرجاء إدخال كلمة المرور') }]}
                >
                    <Input.Password disabled={loading} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                        {t('تسجيل الدخول')}
                    </Button>
                </Form.Item>
            </Form>
        </Flex>
    );
}