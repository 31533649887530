import { Flex, Table, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ViewTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { IntervalType } from "../../api/types";
import useIntervalsForm from "./useIntervalsForm";
import { useQueryIntervals } from "../../api/queries/getIntervals";
import dayjs from "dayjs";

export default function Intervals() {

    const { t } = useTranslation();

    const columns = useMemo(() => [
        {
            title: t('الاسم'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('تاريخ البداية'),
            dataIndex: 'start',
            key: 'start',
            render: (start: string) => dayjs(start).format('YYYY-MM-DD')
        },
        {
            title: t('تاريخ النهاية'),
            dataIndex: 'end',
            key: 'end',
            render: (end: string) => dayjs(end).format('YYYY-MM-DD')
        },
    ], [t]);

    const [params, setParams] = useState({ limit: 10, offset: 0, search: '' });
    const intervals = useQueryIntervals({ ...params });

    const form = useIntervalsForm();

    return (
        <Flex vertical gap={20} style={{ padding: '20px 0' }}>
            {form.contextHolder}
            <ViewTitle title={t('الفترات')} />
            <div>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        form.setOpen(true);
                    }}
                >
                    {t('إضافة')}
                </Button>
            </div>
            <Input.Search
                enterButton
                placeholder={t('بحث')}
                onSearch={search => setParams({ ...params, search })}
                onChange={e => { setParams({ ...params, search: e.target.value }) }}
                loading={intervals.isLoading}
            />
            <Table
                loading={intervals.isLoading}
                columns={columns}
                dataSource={intervals?.data?.data as IntervalType[]}
                pagination={{
                    showQuickJumper: true,
                    showTotal(total, range) {
                        return `${range[0]}-${range[1]} ${t('من')} ${total} ${t('نتيجة')}`;
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                    current: params.offset / params.limit + 1,
                    pageSize: params.limit,
                    total: intervals.data?.total,
                    onChange: (page, pageSize) => setParams({ ...params, offset: (page-1)*pageSize, limit: pageSize }),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => form.setOpen(true, record as IntervalType),
                        style: { cursor: 'pointer' }
                    }
                }}
            />
        </Flex>
    )

}