import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useMutationForm } from "../../hooks";
import { createDepartment, deleteDepartment, updateDepartment } from "../../api/routes/departments";

export default function useDepartmentsForm() {

    const { t } = useTranslation();
    const form = useMutationForm((
        <>
            <Form.Item
                label={t('الاسم')}
                name="name"
                initialValue={""}
                rules={[{ required: true, message: t('الرجاء إدخال الاسم') }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t('الوصف')}
                name="description"
                initialValue={""}
            >
                <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
                label={t('الكود')}
                name="code"
                initialValue={""}
                rules={[{ required: true, message: t('الرجاء إدخال الكود') }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t('المراحل')}
                name="stages"
                initialValue={[]}
                rules={[{ required: true, message: t('الرجاء إدخال المراحل') }]}
            >
                <Select
                    options={[
                        { label: t('ثلاثة مراحل'), value: 3 },
                        { label: t('أربعة مراحل'), value: 4 },
                        { label: t('خمسة مراحل'), value: 5 },
                        { label: t('ستة مراحل'), value: 6 }
                    ]}
                ></Select>
            </Form.Item>
            <Form.Item
                label={t('المراحل النشطة')}
                name="active_stages"
                initialValue={[]}
                rules={[{ required: true, message: t('الرجاء إدخال المراحل النشطة') }]}
            >
                <Select
                    options={[
                        { label: t('المرحلة الأولى'), value: 1 },
                        { label: t('المرحلة الثانية'), value: 2 },
                        { label: t('المرحلة الثالثة'), value: 3 },
                        { label: t('المرحلة الرابعة'), value: 4 },
                        { label: t('المرحلة الخامسة'), value: 5 },
                    ]}
                    mode="multiple"
                ></Select>
            </Form.Item>
        </>
    ), {
        title: t('القسم'),
        queryKey: ['departments'],
        updateFn: updateDepartment,
        createFn: createDepartment,
        deleteFn: deleteDepartment,
    });

    return {
        setOpen: form.setOpen,
        contextHolder: form.contextHolder,
    };

}