import { Avatar, Button, Flex, Typography } from "antd";
import './style.css';
import { useQueryAccount } from "../../api/queries/getAccount";
import { useTranslation } from "react-i18next";

export default function HeaderWidget() {

    const { t } = useTranslation();
    const acoount = useQueryAccount();

    return (
        <Flex
            className="header-widget"
            style={{
                borderRadius: 10,
            }}
            align="between"
        >
            <Flex align="center" gap={10}>
                <Avatar
                    size={40}
                    src="https://static.vecteezy.com/system/resources/thumbnails/002/318/271/small_2x/user-profile-icon-free-vector.jpg"
                />
                <Flex vertical>
                    <Typography.Text>{acoount?.data?.name}</Typography.Text>
                    <Typography.Text type="secondary">{acoount?.data?.email}</Typography.Text>
                </Flex>
            </Flex>
            <Flex align="center" gap={10}>
                <Button
                    onClick={() => {
                        localStorage.removeItem('token');
                        window.location.reload();
                    }}
                >
                    {t('تسجيل الخروج')}
                </Button>
            </Flex>
        </Flex>
    );

}