import { Col, Flex, Layout, Row } from 'antd';
import { Outlet } from 'react-router-dom';

export default function HalfScreen() {

    return (
        <Layout>
            <Row style={{ height: '100vh' }}>
                <Col
                    sm={24}
                    md={12}
                >
                    <Flex
                        justify="center"
                        align="center"
                        style={{ height: '100%' }}
                    >
                        <Outlet />
                    </Flex>
                </Col>
                <Col
                    sm={0}
                    md={12}
                    style={{
                        backgroundImage: 'url(https://source.unsplash.com/random/700x700/?study%20hall)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Row>
        </Layout>
    )

}