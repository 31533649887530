import { useQuery } from '@tanstack/react-query'

import {
    ListResponse,
    QueryResponseType,
    RecordType
} from '../types';
import { getRecords } from '../routes/records';

export const useQueryRecords = (params : {
    limit?: number;
    offset?: number;
    search?: string;
    interval_id?: number;
    department_id?: number;
    location_id?: number;
}) => {
    
    const query = useQuery(
        {
            queryKey: ['records', params],
            queryFn: () => getRecords( { ...params } ),
        }
    ) as QueryResponseType<ListResponse<RecordType[]>>;

    return query;
};