import { Button, Descriptions, Flex, Typography} from "antd";
import StudentData from "../../../api/types/studentData";

export default function Component({
    data,
    onVerify,
}: {
    data: StudentData;
    onVerify?: () => void;
}) {    

    return (
        <Flex vertical gap={20}>
            <Typography.Title level={4} style={{ margin: 0 }}>
                التحقق من المعلومات
            </Typography.Title>
            <Typography.Text>
                يرجى التحقق من صحة المعلومات التالية
            </Typography.Text>
            <Descriptions title="المعلومات المسجلة" column={1}>
                <Descriptions.Item label="الاسم">{data.data.name}</Descriptions.Item>
                <Descriptions.Item label="البريد الالكتروني">{data.data.email}</Descriptions.Item>
                <Descriptions.Item label="القسم">{data.data.department_name}</Descriptions.Item>
                <Descriptions.Item label="المرحلة">{data.data.stage}</Descriptions.Item>
                <Descriptions.Item label="نوع الدراسة">{data.data.study_type}</Descriptions.Item>
            </Descriptions>
            <Button type="primary" onClick={() => onVerify && onVerify()}>تأكيد</Button>
        </Flex>
    )

}