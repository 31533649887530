import { useEffect, useState } from 'react';
import './style.css';

const defaultClassName = ['scroll-area'];

export default function ScrollArea(props: {
    children: React.ReactNode,
    visibleScrollbar?: boolean,
}) {

    const [className, setClassName] = useState<string[]>([])

    useEffect(() => {
        setClassName([
            ...defaultClassName,
            ...(props.visibleScrollbar ? ['visible-scrollbar'] : []),
        ]);
    }, [props]);

    return (
        <div className={className.join(' ')}>
            {props.children}
        </div>
    );

}