import { Form, Input, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useMutationForm } from "../../hooks";
import { useQueryDepartments } from "../../api/queries/getDepartments";
import { useState } from "react";
import { createLocation, deleteLocation, updateLocation } from "../../api/routes/locations";
import { DepartmentType } from "../../api/types";

export default function useLocationsForm() {

    const { t } = useTranslation();

    const [departments_search, setDepartmentsSearch] = useState('');
    const departments = useQueryDepartments({
        search: departments_search,
    });

    const form = useMutationForm((
        <>
            <Form.Item
                label={t('الاسم')}
                name="name"
                initialValue={""}
                rules={[{ required: true, message: t('الرجاء إدخال الاسم') }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t('القسم')}
                name="department_id"
                initialValue={""}
                rules={[{ required: true, message: t('الرجاء إدخال القسم') }]}
            >
                <Select
                    showSearch
                    onSearch={setDepartmentsSearch}
                    filterOption={false}
                    options={departments ? departments.data?.data.map((department: DepartmentType) => ({ value: department.id, label: department.name })) : []}
                ></Select>
            </Form.Item>
            <Form.Item
                label={t('الجولات')}
                name="rounds"
                initialValue={2}
                rules={[{ required: true, message: t('الرجاء إدخال الجولات') }]}
            >
                <InputNumber min={1} />
            </Form.Item>
            <Form.Item
                label={t('الحد الأقصى للطلاب في الجولة')}
                name="max_capacity_per_round"
                initialValue={35}
                rules={[{ required: true, message: t('الرجاء إدخال الحد الأقصى للطلاب في الجولة') }]}
            >
                <InputNumber min={1} />
            </Form.Item>
        </>
    ), {
        title: t('المركز'),
        queryKey: ['locations'],
        updateFn: updateLocation,
        createFn: createLocation,
        deleteFn: deleteLocation,
    });

    return {
        setOpen: form.setOpen,
        contextHolder: form.contextHolder,
    };

}