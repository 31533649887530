import { Flex, Table, Input, Button, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ViewTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { DepartmentType } from "../../api/types";
import useItemsForm from "./useDepartmentsForm";
import { useQueryDepartments } from "../../api/queries/getDepartments";

export default function Units() {

    const { t } = useTranslation();

    const columns = useMemo(() => [
        {
            title: t('الاسم'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('الكود'),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t('المراحل'),
            dataIndex: 'stages',
            key: 'stages',
        },
        {
            title: t('المراحل النشطة'),
            dataIndex: 'active_stages',
            key: 'active_stages',
            render: (active_stages: number[]) => active_stages.join(', ')
        },
    ], [t]);

    const [params, setParams] = useState({ limit: 10, offset: 0, search: '' });
    const items = useQueryDepartments({
        ...params,
    });

    const form = useItemsForm();

    return (
        <Flex vertical gap={20} style={{ padding: '20px 0' }}>
            {form.contextHolder}
            <ViewTitle title={t('الأقسام')} />
            <Space>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        form.setOpen(true);
                    }}
                >
                    {t('إضافة')}
                </Button>
            </Space>
            <Input.Search
                enterButton
                placeholder={t('بحث')}
                onSearch={search => setParams({ ...params, search })}
                onChange={e => { setParams({ ...params, search: e.target.value }) }}
                loading={items.isLoading}
            />
            <Table
                loading={items.isLoading}
                columns={columns}
                dataSource={items.data?.data as any}
                pagination={{
                    showQuickJumper: true,
                    showTotal(total, range) {
                        return `${range[0]}-${range[1]} ${t('من')} ${total} ${t('نتيجة')}`;
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                    current: params.offset / params.limit + 1,
                    pageSize: params.limit,
                    total: items.data?.total,
                    onChange: (page, pageSize) => setParams({ ...params, offset: (page - 1) * pageSize, limit: pageSize }),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            form.setOpen(true, record as DepartmentType);
                        },
                        style: { cursor: 'pointer' }
                    }
                }}
            />
        </Flex>
    )

}