import { apiWithInterceptor } from ".";
import { IntervalType } from "../types";


export const getRecords = async (params: {
    limit?: number;
    offset?: number;
    search?: string;
    interval_id?: number;
    department_id?: number;
    location_id?: number;
    round?: number;
}): Promise<IntervalType[]> => {
    return await apiWithInterceptor.get('/records', { params });
}

export const downloadRecords = async (data: {
    interval_id: number;
    department_id?: number;
    location_id?: number;
    round?: number;
}): Promise<any> => {
    return await apiWithInterceptor.post('/records/download', data);
}

export const deleteRecord = async (id: number): Promise<any> => {
    return await apiWithInterceptor.delete(`/records/${id}`);
}