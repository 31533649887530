import { apiWithoutInterceptor } from ".";
import { IntervalType } from "../types";
import LocationChoice from "../types/locationChoice";
import StudentData from "../types/studentData";

export const current_interval = async (): Promise<IntervalType> => {
    return await apiWithoutInterceptor.get('/records/check');
}

export const start_record = async (data: {
    token: string;
}): Promise<StudentData> => {
    return await apiWithoutInterceptor.post('/records/start', data);
}

// /records/choices
// get available locations
export const get_choices = async (department_id?: number): Promise<LocationChoice[]> => {
    return await apiWithoutInterceptor.get('/records/choices', {
        params: {
            department_id
        }
    });
}

// POST /records
// add record
export const add_record = async (data: {
    token: string;
    location_id: number;
    round: number;
}): Promise<any> => {
    return await apiWithoutInterceptor.post('/records', data);
}