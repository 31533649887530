import { apiWithInterceptor } from '.';
import { DepartmentType } from '../types';

export const getDepartments = async (params: {
    limit?: number;
    offset?: number;
    search?: string;
}): Promise<DepartmentType> => {
    return await apiWithInterceptor.get('/departments', { params });
}

export const getDepartment = async (id: number): Promise<DepartmentType> => {
    return await apiWithInterceptor.get(`/departments/${id}`);
}

export const createDepartment = async (data: {
    name: string;
    description?: string;
    code: string;
    stages: number;
    active_stages: number[];
}): Promise<DepartmentType> => {
    return await apiWithInterceptor.post('/departments', data);
}

export const updateDepartment = async (id: number, data: {
    name?: string;
    description?: string;
    code?: string;
    stages?: number;
    active_stages?: number[];
}): Promise<DepartmentType> => {
    return await apiWithInterceptor.put(`/departments/${id}`, data);
}

export const deleteDepartment = async (id: number): Promise<DepartmentType> => {
    return await apiWithInterceptor.delete(`/departments/${id}`);
}