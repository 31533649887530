import { useEffect, useMemo, useState } from "react";
import useMutation from "./useMutation";
import { Button, Drawer, Form, Space } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export default function useMutationForm(fields: any, {
    title,
    payload,
    idKey = 'id',

    queryKey,
    updateFn,
    createFn,
    deleteFn,
    onDone,
}: {
    title?: string;
    payload?: any;
    idKey?: string;

    queryKey: any;
    updateFn?: (id: number, values: any) => any;
    createFn?: (values: any) => any;
    deleteFn?: (id: number) => any;

    onDone?: (action: 'update' | 'create' | 'delete') => void;
}) {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState<any>(null);
    const [form] = Form.useForm();

    useEffect(() => {
        setItem(payload);
    }, [payload]);

    useEffect(() => {
        form.resetFields();

        if (item) {
            let values: any = {};

            Object.keys(item).forEach(key => {
                if ( typeof item[key] === 'object' && item[key][idKey] ) {
                    values[`${key}_id`] = item[key][idKey];
                } else {
                    // TODO: better date detection and handling needed here
                    // console.log(item[key], isNaN(item[key]), dayjs(item[key]).isValid());
                    // if ( isNaN(item[key]) && item[key] && dayjs(item[key], 'YYYY-MM-DD').isValid() ) {
                        // use regex to detect date format
                    if ( item[key] && String(item[key]).match(/^\d{4}-\d{2}-\d{2}$/) ) {
                        values[key] = dayjs(item[key], 'YYYY-MM-DD');
                        return;
                    }
                    values[key] = item[key];
                }
            });
            
            form.setFieldsValue(values);
        }
    }, [item]);

    const mutation = useMutation({
        queryKey,
        updateFn,
        createFn,
        deleteFn,
        onDone: (action) => {
            if ( action !== 'update' ) {
                setOpen(false);
            }
            onDone && onDone(action);
        },
    });

    const itemIsset = useMemo(() => item && Object.keys(item)?.includes(idKey), [item, idKey]);

    const contextHolder = (
        <>
            {mutation.contextHolder}
            <Drawer
                title={title}
                placement={'left'}
                width={400}
                onClose={() => setOpen(false)}
                open={open}
                extra={
                    <Space>
                        <Button onClick={() => setOpen(false)} size="small">
                            {t('إلغاء')}
                        </Button>
                        {
                            deleteFn && itemIsset && (
                                <Button
                                    size="small"
                                    danger
                                    onClick={() => {
                                        mutation.delete(item[idKey]);
                                    }}
                                >
                                    {t('حذف')}
                                </Button>
                            )
                        }
                    </Space>
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={async (values) => {
                        if (item && Object.keys(item)?.includes(idKey)) {
                            mutation.update(item[idKey], values);
                        } else {
                            mutation.create(values);
                        }
                    }}
                >
                    {fields}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={mutation.loading}>
                            {
                                itemIsset ? t('تحديث') : t('إضافة')
                            }
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );

    return {
        contextHolder,
        open,
        setOpen: (status: boolean, item?: any) => {
            if (item) {
                setItem(item);
            } else {
                setItem(null);
            }
            setOpen( status );
        },
        loading: mutation.loading,
    }

}