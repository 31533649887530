import { useQuery } from '@tanstack/react-query'

import {
    getLocations,
} from '../routes/locations';

import {
    ListResponse,
    QueryResponseType,
    LocationType
} from '../types';

export const useQueryLocations = (params: {
    limit?: number;
    offset?: number;
    search?: string;
    department_id?: number;
}) => {
    
    const query = useQuery(
        {
            queryKey: ['locations', params],
            queryFn: () => getLocations( { ...params } ),
        }
    ) as QueryResponseType<ListResponse<LocationType[]>>;

    return query;
};