import './assets/css/app.css';
import Router from './router';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { ConfigProvider, theme } from 'antd';

const queryClient = new QueryClient()

function App() {

    const { defaultAlgorithm, darkAlgorithm } = theme;
    
    return (
        <ConfigProvider
            direction='rtl'
            theme={{
                algorithm: defaultAlgorithm,
                token: {
                    // Seed Token
                    fontFamily: "'Almarai', sans-serif",
                    colorPrimary: '#fa541c',
                    // colorBgLayout: '#131416',
                    // colorBgContainer: '#181c21',
                },
            }}
        >
            <QueryClientProvider client={queryClient}>
                <Router />
            </QueryClientProvider>
        </ConfigProvider>
    );
}

export default App;
