import { apiWithInterceptor } from '.';
import { LocationType } from '../types';

export const getLocations = async (params: {
    limit?: number;
    offset?: number;
    search?: string;
    department_id?: number;
}): Promise<LocationType> => {
    return await apiWithInterceptor.get('/training_locations', { params });
}

export const getLocation = async (id: number): Promise<LocationType> => {
    return await apiWithInterceptor.get(`/training_locations/${id}`);
}

export const createLocation = async (data: {
    name: string;
    department_id: number;
    rounds: number;
    max_capacity_per_round: number;
}): Promise<LocationType> => {
    return await apiWithInterceptor.post('/training_locations', data);
}

export const updateLocation = async (id: number, data: {
    name?: string;
    department_id?: number;
    rounds?: number;
    max_capacity_per_round?: number;
}): Promise<LocationType> => {
    return await apiWithInterceptor.put(`/training_locations/${id}`, data);
}

export const deleteLocation = async (id: number): Promise<LocationType> => {
    return await apiWithInterceptor.delete(`/training_locations/${id}`);
}