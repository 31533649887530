import { Flex, Table, Input, Button, Space, Select, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ViewTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { LocationType } from "../../api/types";
import { useQueryLocations } from "../../api/queries/getLocations";
import useLocationsForm from "./useLocationsForm";
import { useQueryDepartments } from "../../api/queries/getDepartments";

export default function Locations() {

    const { t } = useTranslation();

    const columns = useMemo(() => [
        {
            title: t('الاسم'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('القسم'),
            dataIndex: 'department',
            key: 'department',
            render: (department: LocationType['department']) => department.name
        },
        {
            title: t('الجولات'),
            dataIndex: 'rounds',
            key: 'rounds',
        },
        {
            title: t('الحد الأقصى للطلاب في الجولة'),
            dataIndex: 'max_capacity_per_round',
            key: 'max_capacity_per_round',
        },
        {
            title: t('عدد الطلاب'),
            dataIndex: 'records_count',
            key: 'records_count',
            render: (records_count: LocationType['records_count']) => (
                <>
                    {
                        records_count.map(({ round, count }) => <p style={{ margin: 0 }}>{(
                            <>
                                {`${t('الجولة')} ${round}`}
                                <Tag color="blue" style={{margin: '2px 5px'}}>{count} {t('طالب')}</Tag>
                            </>
                        )}</p>)
                    }
                </>
            )
        }
    ], [t]);

    const [departmentId, setDepartmentId] = useState<number | undefined>();
    const [departments_search, setDepartmentsSearch] = useState('');
    const departments = useQueryDepartments({
        search: departments_search,
    });

    const [params, setParams] = useState({ limit: 10, offset: 0, search: '', department_id: departmentId });
    const items = useQueryLocations({
        ...params,
    });

    const form = useLocationsForm();

    return (
        <Flex vertical gap={20} style={{ padding: '20px 0' }}>
            {form.contextHolder}
            <ViewTitle title={t('المراكز')} />
            <Space>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        form.setOpen(true);
                    }}
                >
                    {t('إضافة')}
                </Button>
                <Select
                    options={departments.data?.data?.map((department: LocationType['department']) => ({ value: department.id, label: department.name }))}
                    onSearch={setDepartmentsSearch}
                    showSearch={true}
                    placeholder={t('القسم')}
                    style={{ width: 200 }}
                    filterOption={false}
                    onChange={(value) => {
                        setDepartmentId(value);
                        setParams({ ...params, department_id: value });
                    }}
                    value={departmentId}
                    allowClear
                />
            </Space>
            <Input.Search
                enterButton
                placeholder={t('بحث')}
                onSearch={search => setParams({ ...params, search })}
                onChange={e => { setParams({ ...params, search: e.target.value }) }}
                loading={items.isLoading}
            />
            <Table
                loading={items.isLoading}
                columns={columns}
                dataSource={items.data?.data as any}
                pagination={{
                    showQuickJumper: true,
                    showTotal(total, range) {
                        return `${range[0]}-${range[1]} ${t('من')} ${total} ${t('نتيجة')}`;
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                    current: params.offset / params.limit + 1,
                    pageSize: params.limit,
                    total: items.data?.total,
                    onChange: (page, pageSize) => setParams({ ...params, offset: (page - 1) * pageSize, limit: pageSize }),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            form.setOpen(true, record as LocationType);
                        },
                        style: { cursor: 'pointer' }
                    }
                }}
            />
        </Flex>
    )

}