import { useQuery } from '@tanstack/react-query'

import {
    get_choices,
} from '../routes/apply';

import {
    QueryResponseType,
    LocationChoiceType
} from '../types';

export const useLocationsChoices = (department_id?: number) => {
    
    const query = useQuery(
        {
            queryKey: ['locations_choices'],
            queryFn: () => get_choices( department_id ),
            refetchInterval: 1000,
        }
    ) as QueryResponseType<LocationChoiceType>;

    return query;
};