import { Alert, Col, Divider, Flex, Layout, Result, Row, Skeleton, Space, Steps, Tag, Typography, message } from 'antd';
import { SwapLeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Verify from './steps/verify';
import { DepartmentLogo, Logo, LogoMain } from '../../components';
import dayjs from 'dayjs';
import { useCurrentInterval } from '../../api/queries/CurrentInterval';
import CheckData from './steps/checkData';
import StudentData from '../../api/types/studentData';
import SelectLocation from './steps/selectLocation';
import { add_record } from '../../api/routes/apply';

const items = [
    {
        title: 'تسجيل الدخول',
    },
    {
        title: 'التحقق من البيانات',
    },
    {
        title: 'اختيار مكان التدريب',
    },
    {
        title: 'نجاح',
    },
];

export default function Apply() {

    const [step, setStep] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();
    const [studentData, setStudentData] = useState<StudentData>();
    const [locationData, setLocationData] = useState<{
        location_id: number;
        round: number;
    }>();

    useEffect(() => {

        if (!locationData || !studentData) {
            return;
        }

        messageApi.open({
            type: 'loading',
            content: 'جاري إرسال البيانات',
            duration: 20,
        });

        add_record({
            token: studentData.token,
            location_id: locationData.location_id,
            round: locationData.round,
        }).then((res) => {
            messageApi.destroy();
            messageApi.open({
                type: 'success',
                content: 'تم إرسال البيانات بنجاح',
                duration: 20,
            });
            setStep(3);
        }).catch((error) => {
            messageApi.destroy();
            messageApi.open({
                type: 'error',
                content: error.message,
                duration: 10,
            });
        }).finally(() => {

        });

    }, [locationData, studentData]);

    const interval = useCurrentInterval();

    return (
        <Layout>
            {contextHolder}
            <Flex
                justify="start"
                align="center"
                style={{
                    height: '100vh',
                    padding: '20px',
                }}
                vertical
            >
                <Flex
                    vertical
                    gap={0}
                    style={{
                        width: '100%',
                        backgroundColor: 'white',
                        padding: '20px',
                        maxWidth: '700px',
                        borderRadius: '10px',
                    }}
                >
                    <Flex style={{ margin: '0 20px' }} gap={20}>
                        <LogoMain height={70} />
                        <DepartmentLogo height={70} />
                    </Flex>
                    <Flex vertical gap={20} style={{ padding: '20px' }}>
                        <Typography.Title style={{ margin: 0 }} level={3}>{interval.data?.name}</Typography.Title>
                        {
                            interval.error?.message && <Alert message={interval.error?.message} type="error" />
                        }
                        {
                            !interval.error?.message && !interval.isLoading && (
                                <Flex gap={10} wrap="wrap">
                                    <Tag style={{ margin: 0 }} bordered={false} color="orange">{dayjs(interval.data?.start).format('YYYY-MM-DD')}</Tag>
                                    <SwapLeftOutlined />
                                    <Tag style={{ margin: 0 }} bordered={false} color="orange">{dayjs(interval.data?.end).format('YYYY-MM-DD')}</Tag>
                                </Flex>
                            )
                        }
                        {
                            interval.isLoading && (
                                <Skeleton.Input style={{ width: 300 }} active={true} />
                            )
                        }
                        <Divider style={{ margin: '10px 0' }} />
                        {
                            step === 0 && <Verify onVerify={(data) => {
                                setStudentData(data);
                                setStep(1);
                            }} />
                        }
                        <Steps current={step} percent={60} labelPlacement="vertical" items={items} size='small' />
                        {
                            step === 1 && <CheckData data={studentData as any} onVerify={() => {
                                setStep(2);
                            }} />
                        }
                        {
                            step === 2 && <SelectLocation data={studentData as any} onVerify={(data: any) => {
                                setLocationData(data);
                            }} />
                        }
                        {
                            step === 3 && <Flex vertical gap={20}>
                                <Result
                                    status="success"
                                    title="تم تسجيلك بنجاح"
                                    subTitle=""
                                />
                            </Flex>
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Layout>
    )

}