import { useQuery } from '@tanstack/react-query'

import {
    current_interval,
} from '../routes/apply';

import {
    ListResponse,
    QueryResponseType,
    IntervalType
} from '../types';

export const useCurrentInterval = () => {
    
    const query = useQuery(
        {
            queryKey: ['interval'],
            queryFn: () => current_interval(),
        }
    ) as QueryResponseType<IntervalType>;

    return query;
};