import { useRoutes } from 'react-router-dom';

import {
    Apply,
    DashboardLayout,
    HalfScreenLayout,
    NotFound,
} from "./layout";

import {
    LoginView,
    DepartmentsView,
    LocationsView,
    IntervalsView,
    RecordsView,
} from './views';

export default function Router() {

    return useRoutes([
        {
            path: '/',
            element: <Apply />,
        },
        {
            path: '/login',
            element: <HalfScreenLayout />,
            children: [
                {
                    path: '',
                    element: <LoginView />
                },
            ]
        },
        {
            path: '/app',
            element: <DashboardLayout />,
            children: [
                {
                    path: '',
                    element: <DepartmentsView />
                },
                {
                    path: 'locations',
                    children: [
                        {
                            path: '',
                            element: <LocationsView />
                        },
                    ]
                },
                {
                    path: 'intervals',
                    children: [
                        {
                            path: '',
                            element: <IntervalsView />
                        },
                    ]
                },
                {
                    path: 'records',
                    children: [
                        {
                            path: '',
                            element: <RecordsView />
                        },
                    ]
                },
            ]
        },
        {
            path: '*',
            element: <NotFound />
        },
    ]);

}


