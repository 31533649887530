import { Button, Descriptions, Flex, Select, Typography, message } from "antd";
import StudentData from "../../../api/types/studentData";
import { useLocationsChoices } from "../../../api/queries/getLocationChoices";
import LocationChoice from "../../../api/types/locationChoice";
import { useState } from "react";
import { LocationChoiceType } from "../../../api/types";

export default function Component({
    data,
    onVerify,
}: {
    data: StudentData;
    onVerify?: ({
        location_id,
        round,
    }: {
        location_id: number;
        round: number;
    }) => void;
}) {

    const locations = useLocationsChoices(data.data.department_id);
    const [selectedLocation, setSelectedLocation] = useState<LocationChoiceType>();
    const [selectedRound, setSelectedRound] = useState<number>();
    const [messageApi, contextHolder] = message.useMessage();

    return (
        <Flex vertical gap={20}>
            {contextHolder}
            {
                data.data?.department_description?.split('\n').map((line, index) => (
                    line && <Typography.Text key={index}>{line}</Typography.Text>
                ))
            }
            <Typography.Title level={4} style={{ margin: 0 }}>
                اختيار مكان التدريب
            </Typography.Title>
            {/* <Typography.Text>
                يرجى التحقق من صحة المعلومات التالية
            </Typography.Text> */}
            <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="اختر مكان التدريب"
                filterOption={false}
                options={(locations.data as any)?.map((location: LocationChoice) => ({ value: location.location_id, label: location.location_name }))}
                onChange={(value) => setSelectedLocation((locations.data as any)?.find((location: LocationChoice) => location.location_id === value))}
            />
            {
                selectedLocation && (
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="اختر الوجبة"
                        filterOption={false}
                        options={[
                            ...(selectedLocation.active_rounds as any).map((round: number) => ({ value: round, label: `الوجبة ${round}` })),
                        ]}
                        onChange={(value) => setSelectedRound(value)}
                    />
                )
            }
            <Button type="primary" onClick={() => {
                if (!selectedLocation || !selectedRound) {
                    messageApi.open({
                        type: 'error',
                        content: 'يجب اختيار المركز والوجبة'
                    });
                    return;
                }
                onVerify && onVerify({
                    location_id: selectedLocation?.location_id as number,
                    round: selectedRound as number,
                })
            }}>إرسال</Button>
        </Flex>
    )

}