import { useQuery } from '@tanstack/react-query'

import {
    getIntervals,
} from '../routes/intervals';

import {
    ListResponse,
    QueryResponseType,
    IntervalType
} from '../types';

export const useQueryIntervals = (params: {
    limit?: number;
    offset?: number;
    search?: string;
}) => {
    
    const query = useQuery(
        {
            queryKey: ['intervals', params],
            queryFn: () => getIntervals( { ...params } ),
        }
    ) as QueryResponseType<ListResponse<IntervalType[]>>;

    return query;
};