import { Flex, Table, Input, Button, Space, Select, Tooltip, message, Modal, InputNumber } from "antd";
import { ViewTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { RecordType } from "../../api/types";
import { useQueryLocations } from "../../api/queries/getLocations";
import { useQueryDepartments } from "../../api/queries/getDepartments";
import { useQueryRecords } from "../../api/queries/getRecords";
import { useQueryIntervals } from "../../api/queries/getIntervals";
import { LuHardDriveDownload } from "react-icons/lu";

import dayjs from "dayjs";
import { deleteRecord, downloadRecords } from "../../api/routes/records";
import { useMutation } from "../../hooks";

export default function Records() {

    const { t } = useTranslation();

    // export default interface Record {
    //     id: number;
    //     interval: Interval;
    //     created_at: string;
    //     email: string;
    //     name: string;
    //     department: Department;
    //     study_type: number;
    //     stage: number;
    //     round: number;
    //     training_location: Location;
    // }
    const recordsMutation = useMutation({
        queryKey: ['records'],
        // updateFn,
        // createFn,
        deleteFn: deleteRecord,
    });

    const columns = useMemo(() => [
        {
            title: t('الاسم'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('الوجبة'),
            dataIndex: 'round',
            key: 'round',
        },
        {
            title: t('القسم'),
            dataIndex: 'department',
            key: 'department',
            render: (department: RecordType['department']) => department.name
        },
        {
            title: t('المرحلة'),
            dataIndex: 'stage',
            key: 'stage',
        },
        {
            title: t('نوع الدراسة'),
            dataIndex: 'study_type',
            key: 'study_type',
            render: (study_type: RecordType['study_type']) => study_type == 1 ? 'صباحي' : 'مسائي'
        },
        {
            title: t('البريد الالكتروني'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('الفورم'),
            dataIndex: 'interval',
            key: 'interval',
            render: (interval: RecordType['interval']) => <Tooltip title={interval.name}>{interval.name?.slice(0, 20)}{interval.name?.length > 20 ? '...' : ''}</Tooltip>
        },
        {
            title: t('المركز'),
            dataIndex: 'training_location',
            key: 'training_location',
            render: (location: RecordType['training_location']) => location.name
        },
        {
            title: t('التاريخ'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at: string) => (
                <div style={{ direction: 'ltr' }}>
                    <p style={{ margin: 0 }}>{dayjs(created_at).format('YYYY-MM-DD')}</p>
                    <p style={{ margin: 0 }}>{dayjs(created_at).format('hh:mm A')}</p>
                </div>
            )
        },
        {
            title: t('العمليات'),
            key: 'actions',
            render: (record: RecordType) => (
                <Space>
                    <Button type="primary" danger onClick={() => {
                        Modal.confirm({
                            title: t('هل أنت متأكد من حذف') + ' "' + record.name + '"؟',
                            onOk() {
                                recordsMutation.delete(record.id);
                            },
                        });
                    }}>{t('حذف')}</Button>
                </Space>
            )
        }
    ], [t]);

    const [departmentId, setDepartmentId] = useState<number | undefined>();
    const [departments_search, setDepartmentsSearch] = useState('');
    const departments = useQueryDepartments({
        search: departments_search,
    });

    const [intervalId, setIntervalId] = useState<number | undefined>();
    const [intervals_search, setIntervalsSearch] = useState('');
    const intervals = useQueryIntervals({
        search: intervals_search,
    });

    const [locationId, setLocationId] = useState<number | undefined>();
    const [locations_search, setLocationsSearch] = useState('');
    const locations = useQueryLocations({
        search: locations_search,
    });

    const [round, setRound] = useState<number | undefined>();

    const [params, setParams] = useState({
        limit: 10, offset: 0, search: '',
        department_id: departmentId, interval_id: intervalId, location_id: locationId
    });
    const records = useQueryRecords({...params, ...(round ? { round } : {})});

    const [messageApi, contextHolder] = message.useMessage();

    return (
        <Flex vertical gap={20} style={{ padding: '20px 0' }}>
            {contextHolder}
            {recordsMutation.contextHolder}
            <ViewTitle title={t('الإدخالات')} />
            <Space>
                <Select
                    options={departments.data?.data?.map((department: RecordType['department']) => ({ value: department.id, label: department.name }))}
                    onSearch={setDepartmentsSearch}
                    showSearch={true}
                    placeholder={t('القسم')}
                    style={{ width: 200 }}
                    filterOption={false}
                    onChange={(value) => {
                        setDepartmentId(value);
                        setParams({ ...params, department_id: value });
                    }}
                    value={departmentId}
                    allowClear
                />
                <Select
                    options={intervals.data?.data?.map((interval: RecordType['interval']) => ({ value: interval.id, label: interval.name }))}
                    onSearch={setIntervalsSearch}
                    showSearch={true}
                    placeholder={t('الفورمة')}
                    style={{ width: 200 }}
                    filterOption={false}
                    onChange={(value) => {
                        setIntervalId(value);
                        setParams({ ...params, interval_id: value });
                    }}
                    value={intervalId}
                    allowClear
                />
                <Select
                    options={locations.data?.data?.map((location: RecordType['training_location']) => ({ value: location.id, label: location.name }))}
                    onSearch={setLocationsSearch}
                    showSearch={true}
                    placeholder={t('المركز')}
                    style={{ width: 200 }}
                    filterOption={false}
                    onChange={(value) => {
                        setLocationId(value);
                        setParams({ ...params, location_id: value });
                    }}
                    value={locationId}
                    allowClear
                />
                <InputNumber
                    placeholder={t('الوجبة')}
                    style={{ width: 200 }}
                    min={1}
                    onChange={(value) => {
                        if ( value ) setRound(value)
                        else setRound(undefined)
                    }}
                    value={round}
                />

                <Button
                    type="primary"
                    icon={<LuHardDriveDownload />}
                    onClick={() => {
                        if (!intervalId) {
                            messageApi.error(t('الرجاء اختيار فورمة أولا'));
                            return;
                        }
                        messageApi.loading({
                            'content': t('جاري التحميل'),
                            'duration': 999,
                        });
                        downloadRecords({
                            interval_id: intervalId,
                            department_id: departmentId,
                            location_id: locationId,
                            round,
                        }).then((res) => {
                            messageApi.destroy();
                            messageApi.success(t('تم التحميل'));

                            const url = window.URL.createObjectURL(new Blob([res.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            const name = `records ${dayjs().format('YYYY-MM-DD hh:mm A')}.csv`
                            link.setAttribute('download', name);
                            document.body.appendChild(link);
                            link.click();
                        }).catch((err) => {
                            messageApi.destroy();
                            messageApi.error(err.message || t('حدث خطأ'));
                        });
                    }}
                >
                    {t('تصدير')}
                </Button>
            </Space>
            <Input.Search
                enterButton
                placeholder={t('بحث')}
                onSearch={search => setParams({ ...params, search })}
                onChange={e => { setParams({ ...params, search: e.target.value }) }}
                loading={records.isLoading}
            />
            <Table
                loading={records.isLoading}
                columns={columns}
                dataSource={records.data?.data as any}
                pagination={{
                    showQuickJumper: true,
                    showTotal(total, range) {
                        return `${range[0]}-${range[1]} ${t('من')} ${total} ${t('نتيجة')}`;
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                    current: params.offset / params.limit + 1,
                    pageSize: params.limit,
                    total: records.data?.total,
                    onChange: (page, pageSize) => setParams({ ...params, offset: (page - 1) * pageSize, limit: pageSize }),
                }}
            />
        </Flex>
    )

}